
import React, { useState, useEffect } from 'react'
import getFirebase, { FirebaseContext } from './src/components/firebase';

export const wrapRootElement = ({ element }) =>
    <FirebaseProvider>
        {element}
    </FirebaseProvider>

const FirebaseProvider = ({ children }) => {

    const [firebase, setFirebase] = useState(null);

    useEffect(() => {
        const app = import('firebase/compat/app');
        const database = import('firebase/compat/database');
        const firestore = import('firebase/compat/firestore');
        Promise.all([app, database, firestore]).then(values => {
            const firebase = getFirebase(values[0], values[1], values[2]);
            setFirebase(firebase);
        });
    });

    return (
        <FirebaseContext.Provider value={firebase}>
            {children}
        </FirebaseContext.Provider>
    )
}