const config = {
    apiKey: "AIzaSyDAQw3UokibixhL6c9wMAVY82PMxC0H1dk",
    authDomain: "emcorcs.firebaseapp.com",
    databaseURL: "https://emcorcs.firebaseio.com",
    projectId: "emcorcs",
    storageBucket: "emcorcs.appspot.com",
    messagingSenderId: "866890035855",
    appId: "1:866890035855:web:f870b552bd9a719fabf6fa"
};


class Firebase {
    constructor(firebase, database, firestore) {
        firebase.initializeApp(config);
        this.db = firebase.database();
        this.firestore = firebase.firestore();
    }

    // *** API ***

    rsvp = id => this.db.ref(`/rsvpgriffinsophia/${id}`)

    rsvpList = () => this.db.ref(`/rsvpgriffinsophia`)

    rsvpEmail = (info) => {
        const db = this.firestore;
        const name = info.getInvitation();
        db.collection('mail').add({
            to: 'vokoshusseini@gmail.com',
            message: {
                subject: `${name} RSVP'd ${info.getRSVP()} to the wedding!`,
                html: `${name} RSVP'd ${info.getRSVP()} to the wedding!`,
            }
        });
    }

    rsvpSubmittedEmail = (info) => {
        const db = this.firestore;
        const name = info.getInvitation();
        db.collection('mail').add({
            to: 'vokoshusseini@gmail.com',
            message: {
                subject: `${name} RSVP'd ${info.getRSVP()} to the wedding!`,
                html: `
                <h2>
                    ${name} RSVP'd ${info.getRSVP() == 'Yes' ? '<span style="color:#00FF00">Yes</span>' : '<span style="color:#FF0000">No</span>'} to the wedding!
                </h2>

                <p>
                    Attending: ${Object.keys(info.getAttending()).filter(key => info.getAttending()[key] == 1).join(', ')}
                </p>
                <p>
                    ${info.getComment()}
                </p>

                <pre>
                    ${JSON.stringify(info.getInfo())}
                <pre>
                `,
            }
        });
    }
}

let firebase;

function getFirebase(app, database, firestore) {
    if (!firebase) {
        firebase = new Firebase(app.default, database, firestore);
    }

    return firebase;
}

export default getFirebase;